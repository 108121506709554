<div class="col-md-12 mt-3">
    <h1>{{page_title}}</h1>
    <!-- MENSAJE DE AGREGAR UN NUEVO ARTICULO -->
    <p *ngIf="!is_edit">
        Añade un nuevo articulo al blog.
    </p>

    <!-- MENSAJE DE EDITAR UN ARTICULO -->
    <p *ngIf="is_edit && post">
        Modifica el post {{post.title}}.
    </p>
    <hr/>

    <!-- MENSAJE DE EXITO -->
    <div class="alert alert-success col-md-5 mt-3" *ngIf="status == 'success'">
        Los datos se han guardado correctamente !!
    </div>

    <!-- MENSAJE DE ERROR -->
    <div class="alert alert-danger col-md-5" *ngIf="status == 'error'">
        Los datos no se han guardado !!
    </div>

    <!-- FORMULARIO DE CREAR ENTRADA -->
    <form class="col-md-5 ml-0 pl-0" #postNew="ngForm" (ngSubmit)="onSubmit(postNew)">
        
        <div class="form-group">
            <label for="title">Titulo</label>
            <input type="text" name="title" class="form-control" #title="ngModel" [(ngModel)]="post.title" required/>
            
            <small *ngIf="!title.valid && title.touched" class="invalid-feedback d-block">
                El titulo no es válido.
            </small>
        </div>

        <div class="form-group">
            <label for="content">Contenido</label>
            <textarea [froalaEditor] name="content" #content="ngModel" [(ngModel)]="post.content" required></textarea>

            <small *ngIf="!content.valid && content.touched" class="invalid-feedback d-block">
                El contenido no es válido.
            </small>
        </div>

        <div class="form-group">
            <label for="category_id">Categoria</label>
            <select name="category_id" #category="ngModel" [(ngModel)]="post.category_id" class="form-control">
                <option *ngFor="let category of categories" value="{{category.id}}">
                    {{category.name}}
                </option>
            </select>
        </div>

        <div class="form-group">
            <label for="image">Imagen</label>

            <div *ngIf="is_edit && post.image">
                <img src="{{url + 'post/image/' + post.image}}" class="avatar_edit"/>
            </div>

            <!-- [resetUpload]=resetVar -->
            <angular-file-uploader
                [config]="afuConfig"                
                (ApiResponse)="imageUpload($event)">
            </angular-file-uploader>
        </div>

        <input type="submit" value="Guardar" class="btn btn-success" [disabled]="postNew.invalid"/>

    </form>
    <br/>
</div>
