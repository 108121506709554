<div class="container"> <!-- CONTENEDOR PRINCIPAL -->
  <br>
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary rounded"> <!-- BARRA DE NAVEGACION -->
    <a class="navbar-brand" [routerLink] = "['/inicio']">
      <img src="assets/images/angular.png" width="30" height="30" class="d-inline-block align-top" alt="">
      NG-BLOG
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" [routerLink] = "['/inicio']">Inicio <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item" *ngFor="let category of categories">
          <a class="nav-link" [routerLink]="['/categoria', category.id]">{{category.name}}</a>
        </li>        
      </ul>

      <ul class="navbar-nav navbar-right" *ngIf="!identity">
        <li class="nav-item active">
          <a class="nav-link" [routerLink] = "['/login']">Login</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"[routerLink] = "['/registro']">Registro</a>
        </li> 
      </ul>

      <ul class="navbar-nav navbar-right" *ngIf="identity && identity.name">
        <li *ngIf="identity && identity.image" class="nav-item">
          <div class="avatar_mask">
            <img src="{{url + 'user/avatar/' + identity.image}}" class="avatar_nav"/>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ identity.name + ' ' + identity.surname }}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" [routerLink]="['/crear-entrada']">Crear entrada</a>
            <a class="dropdown-item" [routerLink]="['/crear-categoria']">Crear categoria</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" [routerLink]="['/perfil', identity.sub]">Mi perfil</a>
            <a class="dropdown-item" [routerLink]="['/ajustes']">Ajustes</a>
            <a class="dropdown-item" [routerLink]="['/logout/1']">Cerrar sesión</a>
          </div>
        </li>        
      </ul>      
    </div>
  </nav> <!-- CERRAR BARRA DE NAVEGACION -->

  <!-- CONTENIDO -->
  <router-outlet></router-outlet> 

  <footer>
    <hr>
    Master en desarrollo web full-stack con Victor Robles &copy;
    <hr>
  </footer>

</div> <!--CERRAR CONTENEDOR PRINCIPAL -->