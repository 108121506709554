<div class="loading" *ngIf="!category">
    Cargando...
</div>

<div class="container-fluid mt-3" *ngIf="category">
    <h1>{{category.name}}</h1>
    <hr/>

    <div class="loading" *ngIf="!posts">
        Cargando listado...
    </div>

    <div class="alert alert-warning" *ngIf="posts && posts.length == 0">
        No hay entradas que mostrar en la categoria de {{category.name}}.
    </div>
    <!-- LISTADO DE POSTS -->
    <post-list [posts]="posts"
                [identity]="identity"
                [url]="url"></post-list>
</div>