<div class="col-md-12 mt-3">
    <h1>{{page_title}}</h1>
    <p>Identificate en la plataforma para comenzar!!</p>

    <!-- COMIENZO DEL FORMULARIO -->
    <form class="col-md-5 ml-0 pl-0" #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
        <div class="form-group">
            <label for="email">Email</label>
            <input type="email" name="email" class="form-control" #email="ngModel" [(ngModel)]="user.email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">

            <small *ngIf="!email.valid && email.touched" class="invalid-feedback d-block">
                El correo no es valido.
            </small>
        </div>

        <div class="form-group">
            <label for="password">Password</label>
            <input type="password" name="password" class="form-control" #password="ngModel" [(ngModel)]="user.password" required>

            <small *ngIf="!password.valid && password.touched" class="invalid-feedback d-block">
                La contraseña no es válida.
            </small>
        </div>

        <input type="submit" value="Identificarse" class="btn btn-success" [disabled]="loginForm.invalid"/>

    </form>
</div>