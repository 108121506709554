<div class="col-md-12 mt-3">
    <h1>{{page_title}}</h1>
    <p *ngIf="status != 'success'">
        Registrate en nuestra plataforma para crear nuevas entradas y mucho más!!!
    </p>

    <!-- ALERTA DE EXITO DE LA RESPUESTA AJAX-->
    <div class="alert alert-success col-md-5 mt-3" *ngIf="status == 'success'">
        El registro se ha completado correctamente,
        <a [routerLink] = "['/login']">
            Logeate aquí
        </a>
    </div>

    <!-- ALERTA DE EXITO DE LA RESPUESTA AJAX-->
    <div class="alert alert-danger col-md-5 mt-3" *ngIf="status == 'error'">
        El registro no se ha completado, vuelve a intentarlo mas tarde
    </div>

    <!-- FORMULARIO DEL REGISTRO -->
    <form class="col-md-5 ml-0 pl-0" #registerForm="ngForm" (ngSubmit)="onSubmit(registerForm)">
        <div class="form-group">
            <label for="name">Nombre</label>
            <input type="text" name="name" class="form-control" #name="ngModel" [(ngModel)]="user.name" required pattern="[a-zA-Z]+">

            <small *ngIf="!name.valid && name.touched" class="invalid-feedback d-block">
                El nombre no es válido.
            </small>
        </div>

        <div class="form-group">
            <label for="surname">Apellidos</label>
            <input type="text" name="surname" class="form-control" #surname="ngModel" [(ngModel)]="user.surname" required pattern="[a-zA-Z]+">

            <small *ngIf="!surname.valid && surname.touched" class="invalid-feedback d-block">
                Los apellidos no son validos.
            </small>
        </div>

        <div class="form-group">
            <label for="email">Email</label>
            <input type="email" name="email" class="form-control" #email="ngModel" [(ngModel)]="user.email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">

            <small *ngIf="!email.valid && email.touched" class="invalid-feedback d-block"> 
                El correo no es válido.
            </small>
        </div>

        <div class="form-group">
            <label for="password">Contraseña</label>
            <input type="password" name="password" class="form-control" #password="ngModel" [(ngModel)]="user.password" required>

            <small *ngIf="!password.valid && password.touched" class="invalid-feedback d-block">
                La password no es válida.
            </small>
        </div>

        <input type="submit" value="Registrarme" class="btn btn-success" [disabled]="registerForm.invalid"/>
        
    </form>
</div>