<div class="card-columns">
    <article class="card w-100" *ngFor="let post of posts">

        <div class="card-header">
            <a [routerLink]="['/entrada', post.id]">
                <h3>{{post.title}}</h3>
            </a>
        </div>

        <div class="card-body">
            <!-- IMAGEN -->
            <div class="post-image w-100" *ngIf="post.image">
                <img src="{{ url + 'post/image/' + post.image }}" class="w-100" />
            </div>

            <div class="post-image w-100" *ngIf="!post.image">
                <img src="assets/images/default.png" class="w-100" />
            </div>

            <!-- BOTONES DE ACCIÓN -->
            <div class="post-buttons mt-3">
                <a [routerLink]="['/entrada', post.id]" class="btn btn-success mr-2">Leer</a>

                <!-- OCULTAR BOTONES CRITICOS PARA LOS USUARIOS QUE NO ESTAN LOGUEADOS -->
                <span *ngIf="identity && post.user_id == identity.sub">
                    <a [routerLink]="['/editar-entrada', post.id]" class="btn btn-warning mr-2">Editar</a>

                    <!-- Button to Open the Modal -->
                    <button type="button" class="btn btn-danger" data-toggle="modal"
                        [attr.data-target]="'#myModal' + post.id">
                        Borrar
                    </button>

                    <!-- The Modal -->
                    <div class="modal" id="myModal{{post.id}}">
                        <div class="modal-dialog">
                            <div class="modal-content">

                                <!-- Modal Header -->
                                <div class="modal-header">
                                    <h4 class="modal-title">¿Seguro que quieres borrar esta entrada?</h4>
                                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                                </div>

                                <!-- Modal body -->
                                <div class="modal-body">
                                    Vas a eliminar definitivamente la entrada {{post.title}}
                                </div>

                                <!-- Modal footer -->
                                <div class="modal-footer">
                                    <a (click)="deletePost(post.id)" class="btn btn-danger text-light"
                                        data-dismiss="modal">Borrar</a>
                                    <button type="button" class="btn btn-warning" data-dismiss="modal">Close</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </span>
            </div>
        </div>
    </article>
</div>